// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  // --ion-safe-area-top: 20px;
  // --ion-safe-area-bottom: 22px;
  --ion-font-family: "Manrope";
  --ion-default-font: "Manrope";
  /** primary **/
  --ion-color-primary: #ffb83d;
  --ion-color-primary-rgb: 255, 184, 61;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e0a236;
  --ion-color-primary-tint: #ffbf50;

  /** secondary **/
  --ion-color-secondary: #718CAC;
  --ion-color-secondary-rgb: 113, 140, 172;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #637b97;
  --ion-color-secondary-tint: #7f98b4;
  // --ion-color-secondary: #fff8f0;
  // --ion-color-secondary-rgb: 255, 248, 240;
  // --ion-color-secondary-contrast: #000000;
  // --ion-color-secondary-contrast-rgb: 0, 0, 0;
  // --ion-color-secondary-shade: #e0dad3;
  // --ion-color-secondary-tint: #fff9f2;

  /** tertiary **/
  --ion-color-tertiary: #5197c6;
  --ion-color-tertiary-rgb: 81, 151, 198;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4785ae;
  --ion-color-tertiary-tint: #62a1cc;

  /** success **/
  --ion-color-success: #0BC15C;
  --ion-color-success-rgb: 11, 193, 92;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0aaa51;
  --ion-color-success-tint: #23c76c;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #D9525E;
	--ion-color-danger-rgb: 217,82,94;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #bf4853;
	--ion-color-danger-tint: #dd636e;

  /** dark figmadaki darkest **/
  --ion-color-dark: #222222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light figmadaki lightest **/
  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 248, 248, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dadada;
  --ion-color-light-tint: #f9f9f9;

  /** White **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** Black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** Medium Light - figmadaki light **/
  --ion-color-medium-light: #9F9F9F;
  --ion-color-medium-light-rgb: 159, 159, 159;
  --ion-color-medium-light-contrast: #000000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #8c8c8c;
  --ion-color-medium-light-tint: #a9a9a9;

  /** Medium Dark figmadaki dark **/
  --ion-color-medium-dark: #7b7b7b;
  --ion-color-medium-dark-rgb: 123, 123, 123;
  --ion-color-medium-dark-contrast: #ffffff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #6c6c6c;
  --ion-color-medium-dark-tint: #888888;

  /** Ultra Light (white) figmadaki full **/
  --ion-color-ultra-light: #ffffff;
  --ion-color-ultra-light-rgb: 255, 255, 255;
  --ion-color-ultra-light-contrast: #000000;
  --ion-color-ultra-light-contrast-rgb: 0, 0, 0;
  --ion-color-ultra-light-shade: #e0e0e0;
  --ion-color-ultra-light-tint: #ffffff;

  /** Ultra Dark (black) figmadaki none **/
  --ion-color-ultra-dark: #000000;
  --ion-color-ultra-dark-rgb: 0, 0, 0;
  --ion-color-ultra-dark-contrast: #ffffff;
  --ion-color-ultra-dark-contrast-rgb: 255, 255, 255;
  --ion-color-ultra-dark-shade: #000000;
  --ion-color-ultra-dark-tint: #1a1a1a;

  /** Lighter figmada da aynı isimle **/
  --ion-color-lighter: #E9E9E9;
  --ion-color-lighter-rgb: 233, 233, 233;
  --ion-color-lighter-contrast: #000000;
  --ion-color-lighter-contrast-rgb: 0, 0, 0;
  --ion-color-lighter-shade: #cdcdcd;
  --ion-color-lighter-tint: #ebebeb;

  /** Darker figmada da aynı isimle **/
  --ion-color-darker: #333333;
  --ion-color-darker-rgb: 51, 51, 51;
  --ion-color-darker-contrast: #ffffff;
  --ion-color-darker-contrast-rgb: 255, 255, 255;
  --ion-color-darker-shade: #2d2d2d;
  --ion-color-darker-tint: #474747;

  /** Overlay **/
  --ion-color-overlay: #f2f4f5;
  --ion-color-overlay-rgb: 242, 244, 245;
  --ion-color-overlay-contrast: #000000;
  --ion-color-overlay-contrast-rgb: 0, 0, 0;
  --ion-color-overlay-shade: #d5d7d8;
  --ion-color-overlay-tint: #f3f5f6;

  /** Night Blue **/
  --ion-color-night-blue: #304659;
  --ion-color-night-blue-rgb: 48, 70, 89;
  --ion-color-night-blue-contrast: #ffffff;
  --ion-color-night-blue-contrast-rgb: 255, 255, 255;
  --ion-color-night-blue-shade: #2a3e4e;
  --ion-color-night-blue-tint: #45596a;

  /** Vra Orange **/
  --ion-color-addition-1: #F09E5A;
  --ion-color-addition-1-rgb: 240, 158, 90;
  --ion-color-addition-1-contrast: #ffffff;
  --ion-color-addition-1-contrast-rgb: 255, 255, 255;
  --ion-color-addition-1-shade: #d38b4f;
  --ion-color-addition-1-tint: #f2a86b;

  /** Spraying Green **/
  --ion-color-addition-2: #95C080;
  --ion-color-addition-2-rgb: 149, 192, 128;
  --ion-color-addition-2-contrast: #ffffff;
  --ion-color-addition-2-contrast-rgb: 255, 255, 255;
  --ion-color-addition-2-shade: #83a971;
  --ion-color-addition-2-tint: #a0c68d;

  /** Irrigation Blue **/
  --ion-color-addition-3: #719BC0;
  --ion-color-addition-3-rgb: 113, 155, 192;
  --ion-color-addition-3-contrast: #FFFFFF;
  --ion-color-addition-3-contrast-rgb: 255, 255, 255;
  --ion-color-addition-3-shade: #6388a9;
  --ion-color-addition-3-tint: #7fa5c6;

  /** Comparison Yellow **/
  --ion-color-addition-4: #FFBF4F;
  --ion-color-addition-4-rgb: 255, 191, 79;
  --ion-color-addition-4-contrast: #ffffff;
  --ion-color-addition-4-contrast-rgb: 255, 255, 255;
  --ion-color-addition-4-shade: #e0a846;
  --ion-color-addition-4-tint: #ffc561;

  /** Weather Status Gray **/
  --ion-color-addition-5: #C9D4E2;
  --ion-color-addition-5-rgb: 201, 212, 226;
  --ion-color-addition-5-contrast: #000000;
  --ion-color-addition-5-contrast-rgb: 0, 0, 0;
  --ion-color-addition-5-shade: #b1bbc7;
  --ion-color-addition-5-tint: #ced8e5;


  --ion-background-color: #F8F8F8; // #f2f4f5 ;
  --ion-background-color-rgb: 248, 248, 248; //242, 244, 245;

  --ion-items-background-color: #F8F8F8;
  --ion-items-background-color-rgb: 248, 248, 248;

  --ion-text-color: #242424;
  --ion-text-color-rgb: 36, 36, 36;

  --ion-text-color-alt: #9E9E9E;
  --ion-text-color-alt-rgb: 158, 158, 158;

  // --ion-backdrop-color: "";
  // --ion-backdrop-opacity: "";
  // --ion-overlay-background-color: "";

  // --ion-border-color: "";
  // --ion-box-shadow-color: "";

  /** Tab colors **/
  --ion-tab-bar-background: #ffffff;
  --ion-tab-bar-background-focused: #ffffff;
  --ion-tab-bar-border-color: #ffffff00;
  --ion-tab-bar-color: var(--ion-color-medium);
  --ion-tab-bar-color-selected: var(--ion-color-primary);

  /** Toolbar Colors **/
  --ion-toolbar-background: var(--ion-color-ultra-light); //#e5e5e500;
  --ion-toolbar-border-color: var(--ion-color-ultra-light); // #e5e5e500;
  // --ion-toolbar-color: "";
  // --ion-toolbar-segment-color: "";
  // --ion-toolbar-segment-color-checked: "";
  // --ion-toolbar-segment-background: "";
  // --ion-toolbar-segment-background-checked: "";
  // --ion-toolbar-segment-indicator-color: "";

  --ion-item-background: #ffffff;
  --ion-item-border-color: #ffffff;
  // --ion-item-color: #FFFFFF;

  // --ion-placeholder-color: "";

  --ion-card-background: #ffffff;

  --ion-color-step-50: #e8eaeb;
  --ion-color-step-100: #dddfe0;
  --ion-color-step-150: #d3d5d6;
  --ion-color-step-200: #c9cacb;
  --ion-color-step-250: #bfc0c1;
  --ion-color-step-300: #b4b6b6;
  --ion-color-step-350: #aaabac;
  --ion-color-step-400: #a0a1a1;
  --ion-color-step-450: #959697;
  --ion-color-step-500: #8b8c8d;
  --ion-color-step-550: #818282;
  --ion-color-step-600: #767778;
  --ion-color-step-650: #6c6d6d;
  --ion-color-step-700: #626263;
  --ion-color-step-750: #585858;
  --ion-color-step-800: #4d4e4e;
  --ion-color-step-850: #434343;
  --ion-color-step-900: #393939;
  --ion-color-step-950: #2e2e2e;

  --ion-color-tab-primary: #ffffff;
  --ion-color-tab-primary-rgb: 255, 255, 255;
  --ion-color-tab-primary-contrast: #000000;
  --ion-color-tab-primary-contrast-rgb: 0, 0, 0;
  --ion-color-tab-primary-shade: #e0e0e0;
  --ion-color-tab-primary-tint: #ffffff;

  --doktar-intl-phone-input-size: 35px;
  --doktar-intl-phone-input-color: #a9a9a9;
  --doktar-intl-phone-input-bg-color: var(--ion-color-tab-primary);
  --doktar-intl-phone-input-thickness: 1px;
  --doktar-intl-phone-input-icon-color: #333333;

  --swiper-pagination-bullet-inactive-color: var(--ion-color-medium);
  --swiper-pagination-color: var(--ion-color-dark);
  --swiper-theme-color: var(--ion-color-dark);

}

.ion-color-tab-primary {
  --ion-color-base: var(--ion-color-tab-primary);
  --ion-color-base-rgb: var(--ion-color-tab-primary-rgb);
  --ion-color-contrast: var(--ion-color-tab-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tab-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tab-primary-shade);
  --ion-color-tint: var(--ion-color-tab-primary-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-medium-light {
  --ion-color-base: var(--ion-color-medium-light);
  --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
  --ion-color-contrast: var(--ion-color-medium-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-light-shade);
  --ion-color-tint: var(--ion-color-medium-light-tint);
}

.ion-color-medium-dark {
  --ion-color-base: var(--ion-color-medium-dark);
  --ion-color-base-rgb: var(--ion-color-medium-dark-rgb);
  --ion-color-contrast: var(--ion-color-medium-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-dark-shade);
  --ion-color-tint: var(--ion-color-medium-dark-tint);
}

.ion-color-ultra-light {
  --ion-color-base: var(--ion-color-ultra-light);
  --ion-color-base-rgb: var(--ion-color-ultra-light-rgb);
  --ion-color-contrast: var(--ion-color-ultra-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ultra-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-ultra-light-shade);
  --ion-color-tint: var(--ion-color-ultra-light-tint);
}

.ion-color-ultra-dark {
  --ion-color-base: var(--ion-color-ultra-dark);
  --ion-color-base-rgb: var(--ion-color-ultra-dark-rgb);
  --ion-color-contrast: var(--ion-color-ultra-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ultra-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-ultra-dark-shade);
  --ion-color-tint: var(--ion-color-ultra-dark-tint);
}

.ion-color-overlay {
  --ion-color-base: var(--ion-color-overlay);
  --ion-color-base-rgb: var(--ion-color-overlay-rgb);
  --ion-color-contrast: var(--ion-color-overlay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-overlay-contrast-rgb);
  --ion-color-shade: var(--ion-color-overlay-shade);
  --ion-color-tint: var(--ion-color-overlay-tint);
}

.ion-color-night-blue {
  --ion-color-base: var(--ion-color-night-blue);
  --ion-color-base-rgb: var(--ion-color-night-blue-rgb);
  --ion-color-contrast: var(--ion-color-night-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-night-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-night-blue-shade);
  --ion-color-tint: var(--ion-color-night-blue-tint);
}

.ion-color-lighter {
  --ion-color-base: var(--ion-color-lighter);
  --ion-color-base-rgb: var(--ion-color-lighter-rgb);
  --ion-color-contrast: var(--ion-color-lighter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lighter-contrast-rgb);
  --ion-color-shade: var(--ion-color-lighter-shade);
  --ion-color-tint: var(--ion-color-lighter-tint);
}

.ion-color-darker {
  --ion-color-base: var(--ion-color-darker);
  --ion-color-base-rgb: var(--ion-color-darker-rgb);
  --ion-color-contrast: var(--ion-color-darker-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darker-contrast-rgb);
  --ion-color-shade: var(--ion-color-darker-shade);
  --ion-color-tint: var(--ion-color-darker-tint);
}

.ion-color-addition-1 {
  --ion-color-base: var(--ion-color-addition-1);
  --ion-color-base-rgb: var(--ion-color-addition-1-rgb);
  --ion-color-contrast: var(--ion-color-addition-1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-addition-1-contrast-rgb);
  --ion-color-shade: var(--ion-color-addition-1-shade);
  --ion-color-tint: var(--ion-color-addition-1-tint);
}

.ion-color-addition-2 {
  --ion-color-base: var(--ion-color-addition-2);
  --ion-color-base-rgb: var(--ion-color-addition-2-rgb);
  --ion-color-contrast: var(--ion-color-addition-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-addition-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-addition-2-shade);
  --ion-color-tint: var(--ion-color-addition-2-tint);
}

.ion-color-addition-3 {
  --ion-color-base: var(--ion-color-addition-3);
  --ion-color-base-rgb: var(--ion-color-addition-3-rgb);
  --ion-color-contrast: var(--ion-color-addition-3-contrast);
  --ion-color-contrast-rgb: var(--ion-color-addition-3-contrast-rgb);
  --ion-color-shade: var(--ion-color-addition-3-shade);
  --ion-color-tint: var(--ion-color-addition-3-tint);
}

.ion-color-addition-4 {
  --ion-color-base: var(--ion-color-addition-4);
  --ion-color-base-rgb: var(--ion-color-addition-4-rgb);
  --ion-color-contrast: var(--ion-color-addition-4-contrast);
  --ion-color-contrast-rgb: var(--ion-color-addition-4-contrast-rgb);
  --ion-color-shade: var(--ion-color-addition-4-shade);
  --ion-color-tint: var(--ion-color-addition-4-tint);
}

.ion-color-addition-5 {
  --ion-color-base: var(--ion-color-addition-5);
  --ion-color-base-rgb: var(--ion-color-addition-5-rgb);
  --ion-color-contrast: var(--ion-color-addition-5-contrast);
  --ion-color-contrast-rgb: var(--ion-color-addition-5-contrast-rgb);
  --ion-color-shade: var(--ion-color-addition-5-shade);
  --ion-color-tint: var(--ion-color-addition-5-tint);
}

body{
  background-color: transparent!important;
  background: transparent!important;
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
  /** primary **/
  --ion-color-primary: #ffb83d;
  --ion-color-primary-rgb: 255, 184, 61;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e0a236;
  --ion-color-primary-tint: #ffbf50;

  /** secondary **/
  --ion-color-secondary: #ffdfa7;
  --ion-color-secondary-rgb: 255, 223, 167;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0c493;
  --ion-color-secondary-tint: #ffe2b0;
  // --ion-color-secondary: #fff8f0;
  // --ion-color-secondary-rgb: 255, 248, 240;
  // --ion-color-secondary-contrast: #000000;
  // --ion-color-secondary-contrast-rgb: 0, 0, 0;
  // --ion-color-secondary-shade: #e0dad3;
  // --ion-color-secondary-tint: #fff9f2;

  /** tertiary **/
  --ion-color-tertiary: #5197c6;
  --ion-color-tertiary-rgb: 81, 151, 198;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4785ae;
  --ion-color-tertiary-tint: #62a1cc;

  /** success **/
  --ion-color-success: #0BC15C;
  --ion-color-success-rgb: 11, 193, 92;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0aaa51;
  --ion-color-success-tint: #23c76c;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #D9525E;
	--ion-color-danger-rgb: 217,82,94;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #bf4853;
	--ion-color-danger-tint: #dd636e;

  --ion-color-dark: #DDDDDD;
  --ion-color-dark-rgb: 221, 221, 221;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #c2c2c2;
  --ion-color-dark-tint: #e0e0e0;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #141414;
  --ion-color-light-rgb: 20, 20, 20;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #121212;
  --ion-color-light-tint: #2c2c2c;

  /** Medium Light **/
  --ion-color-medium-light: #333333;
  --ion-color-medium-light-rgb: 51, 51, 51;
  --ion-color-medium-light-contrast: #ffffff;
  --ion-color-medium-light-contrast-rgb: 255, 255, 255;
  --ion-color-medium-light-shade: #2d2d2d;
  --ion-color-medium-light-tint: #474747;

  /** Medium Dark **/
  --ion-color-medium-dark: #7B7B7B;
  --ion-color-medium-dark-rgb: 123, 123, 123;
  --ion-color-medium-dark-contrast: #ffffff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #6c6c6c;
  --ion-color-medium-dark-tint: #888888;

  /** Ultra Light **/
  // --ion-color-ultra-light: #000000;
  // --ion-color-ultra-light-rgb: 0, 0, 0;
  // --ion-color-ultra-light-contrast: #ffffff;
  // --ion-color-ultra-light-contrast-rgb: 255, 255, 255;
  // --ion-color-ultra-light-shade: #000000;
  // --ion-color-ultra-light-tint: #1a1a1a;
  --ion-color-ultra-light: #1c1c1d;
  --ion-color-ultra-light-rgb: 28, 28, 29;
  --ion-color-ultra-light-contrast: #ffffff;
  --ion-color-ultra-light-contrast-rgb: 255, 255, 255;
  --ion-color-ultra-light-shade: #19191a;
  --ion-color-ultra-light-tint: #333334;

  /** Ultra Dark (black) **/
  --ion-color-ultra-dark: #ffffff;
  --ion-color-ultra-dark-rgb: 255, 255, 255;
  --ion-color-ultra-dark-contrast: #000000;
  --ion-color-ultra-dark-contrast-rgb: 0, 0, 0;
  --ion-color-ultra-dark-shade: #e0e0e0;
  --ion-color-ultra-dark-tint: #ffffff;

  /** Lighter figmada da aynı isimle **/
  --ion-color-lighter: #232323;
  --ion-color-lighter-rgb: 35, 35, 35;
  --ion-color-lighter-contrast: #ffffff;
  --ion-color-lighter-contrast-rgb: 255, 255, 255;
  --ion-color-lighter-shade: #1f1f1f;
  --ion-color-lighter-tint: #393939;

  --ion-color-darker: #9F9F9F;
  --ion-color-darker-rgb: 159, 159, 159;
  --ion-color-darker-contrast: #000000;
  --ion-color-darker-contrast-rgb: 0, 0, 0;
  --ion-color-darker-shade: #8c8c8c;
  --ion-color-darker-tint: #a9a9a9;


  /** Overlay **/
  --ion-color-overlay: #1e292f;
  --ion-color-overlay-rgb: 30, 41, 47;
  --ion-color-overlay-contrast: #ffffff;
  --ion-color-overlay-contrast-rgb: 255, 255, 255;
  --ion-color-overlay-shade: #1a2429;
  --ion-color-overlay-tint: #353e44;

  /** Night Blue **/
  --ion-color-night-blue: #cfb9a6;
  --ion-color-night-blue-rgb: 207, 185, 166;
  --ion-color-night-blue-contrast: #000000;
  --ion-color-night-blue-contrast-rgb: 0, 0, 0;
  --ion-color-night-blue-shade: #b6a392;
  --ion-color-night-blue-tint: #d4c0af;

  --ion-background-color: #141414;
  --ion-background-color-rgb: 20, 20, 20;

  --ion-items-background-color: #070707;
  --ion-items-background-color-rgb: 7, 7, 7;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-text-color-alt: #4c4c4c;
  --ion-text-color-alt-rgb: 50, 168, 82;
  // --ion-backdrop-color: "";
  // --ion-backdrop-opacity: "";
  // --ion-overlay-background-color: "";

  // --ion-border-color: "";
  // --ion-box-shadow-color: "";

  /** Tab colors **/
  --ion-tab-bar-background: #121212;
  --ion-tab-bar-background-focused: #121212;
  --ion-tab-bar-border-color: #121212;
  --ion-tab-bar-color: var(--ion-color-medium);
  --ion-tab-bar-color-selected: var(--ion-color-primary);

  /** Toolbar Colors **/
  --ion-toolbar-background: var(--ion-color-ultra-light); //#e5e5e500;
  --ion-toolbar-border-color: var(--ion-color-ultra-light); // #e5e5e500;
  // --ion-toolbar-background: var(--ion-background-color); //#e5e5e500;
  // --ion-toolbar-border-color: var(--ion-background-color); // #e5e5e500;
  // --ion-toolbar-color: "";
  // --ion-toolbar-segment-color: "";
  // --ion-toolbar-segment-color-checked: "";
  // --ion-toolbar-segment-background: "";
  // --ion-toolbar-segment-background-checked: "";
  // --ion-toolbar-segment-indicator-color: "";

  --ion-item-background: #1c1c1d;
  --ion-item-border-color: #1c1c1d;
  // --ion-item-color: #FFFFFF;

  // --ion-placeholder-color: "";

  --ion-card-background: #1c1c1d;

  --ion-color-tab-primary: #121212;
  --ion-color-tab-primary-rgb: 18, 18, 18;
  --ion-color-tab-primary-contrast: #ffffff;
  --ion-color-tab-primary-contrast-rgb: 255, 255, 255;
  --ion-color-tab-primary-shade: #16171a;
  --ion-color-tab-primary-tint: #000000;


  --doktar-intl-phone-input-color: #565656;
  --doktar-intl-phone-input-bg-color: var(--ion-color-tab-primary);
  --doktar-intl-phone-input-icon-color: #cccccc;


  --swiper-pagination-bullet-inactive-color: var(--ion-color-medium);
  --swiper-pagination-color: var(--ion-color-dark);
  --swiper-theme-color: var(--ion-color-dark);
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  // --ion-background-color: #000000;
  // --ion-background-color-rgb: 0, 0, 0;

  // --ion-text-color: #ffffff;
  // --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #2d2f33;
  --ion-color-step-100: #383a3e;
  --ion-color-step-150: #434548;
  --ion-color-step-200: #4e5053;
  --ion-color-step-250: #595b5e;
  --ion-color-step-300: #646669;
  --ion-color-step-350: #6f7173;
  --ion-color-step-400: #7a7c7e;
  --ion-color-step-450: #858789;
  --ion-color-step-500: #919294;
  --ion-color-step-550: #9c9c9e;
  --ion-color-step-600: #a7a7a9;
  --ion-color-step-650: #b2b2b4;
  --ion-color-step-700: #bdbdbf;
  --ion-color-step-750: #c8c8c9;
  --ion-color-step-800: #d3d3d4;
  --ion-color-step-850: #dededf;
  --ion-color-step-900: #e9e9ea;
  --ion-color-step-950: #f4f4f4;

  // --ion-item-background: #222428;
  // --ion-toolbar-background: #222428;

  // --ion-card-background: #1c1c1d;
}

// .ios ion-modal {
//   --ion-background-color: var(--ion-color-step-100);
//   --ion-toolbar-background: var(--ion-color-step-100);
//   --ion-toolbar-border-color: var(--ion-color-step-250);
// }

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  // --ion-background-color: #121212;
  // --ion-background-color-rgb: 18, 18, 18;

  // --ion-text-color: #ffffff;
  // --ion-text-color-rgb: 255, 255, 255;

  // --ion-border-color: #222222;

  --ion-color-step-50: #2d2f33;
  --ion-color-step-100: #383a3e;
  --ion-color-step-150: #434548;
  --ion-color-step-200: #4e5053;
  --ion-color-step-250: #595b5e;
  --ion-color-step-300: #646669;
  --ion-color-step-350: #6f7173;
  --ion-color-step-400: #7a7c7e;
  --ion-color-step-450: #858789;
  --ion-color-step-500: #919294;
  --ion-color-step-550: #9c9c9e;
  --ion-color-step-600: #a7a7a9;
  --ion-color-step-650: #b2b2b4;
  --ion-color-step-700: #bdbdbf;
  --ion-color-step-750: #c8c8c9;
  --ion-color-step-800: #d3d3d4;
  --ion-color-step-850: #dededf;
  --ion-color-step-900: #e9e9ea;
  --ion-color-step-950: #f4f4f4;

  // --ion-item-background: #1e1e1e;

  // --ion-toolbar-background: #1e1e1e;

  // --ion-tab-bar-background: #1f1f1f;

  // --ion-card-background: #1e1e1e;
}

// }
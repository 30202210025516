// ion-button[shape="round"] {
//     --border-radius: 15px;
//     // --padding-top: 0;
//     // --padding-start: 26px;
//     // --padding-end: 26px;
//     // --padding-bottom: 0;
// }
// @import url(https://fonts.googleapis.com/css2?family=Manrope);
$constSafeareaInsetBottom: constant(safe-area-inset-bottom);
$constSafeareaInsetTop: constant(safe-area-inset-top);
$envSafeareaInsetBottom: env(safe-area-inset-bottom);
$envSafeareaInsetTop: env(safe-area-inset-top);

// html {
// 	// --ion-safe-area-top: constant(safe-area-inset-top);
// 	// --ion-safe-area-bottom: constant(safe-area-inset-bottom);
// 	// --ion-safe-area-top: env(safe-area-inset-top);
// 	// --ion-safe-area-bottom: env(safe-area-inset-bottom);
// }

// .ios-safe-area-margin {
// 	// margin-top: constant(safe-area-inset-top);
// 	// margin-bottom: constant(safe-area-inset-bottom);
// 	// margin-top: env(safe-area-inset-top);
// 	// margin-bottom: env(safe-area-inset-bottom);
// }

// // $safeareaInsetBottom: var(--ion-safe-area-bottom); // env(safe-area-inset-bottom);
// $safeareaInsetTop: var(--ion-safe-area-top); // env(safe-area-inset-top);

::ng-deep {
	.item-disable {
		opacity: 0.5 !important;
	}
}

// ::ng-deep {
//     .overscroll {
//         &::before,
//         &::after {
//             position: unset !important;
//             width: 0 !important;
//             height: 0 !important;
//             content: "" !important;
//         }
//         &::before {
//             bottom: 0 !important;
//         }
//         &::after {
//             top: 0 !important;
//         }
//     }
// }

* {
	font-family: "Manrope";
}

html.plt-android {
	// ion-content {
	//     ion-header {
	//         padding-top: 0;
	//     }
	// }
	ion-header:first-of-type {
		padding-top: 30px;

		&[collapse="condense"] {
			padding-top: 0px;

			&.orbit-header {
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 41px;
			}
		}

		// padding-bottom: env(safe-area-inset-bottom);
		&:not(.orbit-header) {
			background-color: var(--ion-color-ultra-light);
		}

		&.orbit-header {
			background-color: var(--ion-color-ultra-light);
			// background: rgba(var(--ion-color-ultra-light-rgb), 0.8) !important;

			// opacity: .8!important;

			ion-toolbar {
				background-color: var(--ion-color-ultra-light);
				// background: #00000000 !important;
				--opacity: 0 !important;

				* {
					--opacity: 1 !important;
				}
			}
		}
	}

	/**
    *   full screen appte, sayfanın en tepesinde konumlanacak fab btn için ek top değeri tanımlanır.
    * böylece status bardaki herhangi bir elementten korunmuş olur.
    */
	.ion-fab-md-top-padding {
		top: 30px !important;
	}

	orbit-modal-base {
		ion-header:first-of-type {
			padding-top: 0;
		}
	}
}

html.plt-ios {
	ion-header:first-of-type {
		// padding-top: 25px;
		// padding-top: calc(constant(safe-area-inset-top) * 0.75);
		padding-top: constant(safe-area-inset-top);
		// padding-bottom: constant(safe-area-inset-bottom);
		// padding-top: calc(env(safe-area-inset-top) * 0.75);
		padding-top: env(safe-area-inset-top);
		// padding-bottom: env(safe-area-inset-bottom);

		&[collapse="condense"] {
			padding-top: 0px;

			&.orbit-header {
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 41px;
			}
		}

		&:not(.orbit-header) {
			background-color: var(--ion-color-ultra-light);
		}

		&.orbit-header {
			background: rgba(var(--ion-color-ultra-light-rgb), 0.8) !important;

			&.solid {
				background: rgba(var(--ion-color-ultra-light-rgb), 1) !important;
			}

			// opacity: .8!important;

			ion-toolbar {
				background: #00000000 !important;
				--opacity: 0 !important;

				* {
					--opacity: 1 !important;
				}
			}
		}
	}

	ion-tab-bar {
		padding-bottom: calc(constant(safe-area-inset-bottom) * 1);
		padding-bottom: calc(env(safe-area-inset-bottom) * 1);
		// background: var(--ion-tab-bar-background) !important;
	}

	/**
    *   full screen appte, sayfanın en tepesinde konumlanacak fab btn için ek top değeri tanımlanır.
    * böylece status bardaki herhangi bir elementten korunmuş olur.
    */
	.ion-fab-md-top-padding {
		top: calc(constant(safe-area-inset-top) + 0px) !important;
		top: calc(env(safe-area-inset-top) + 0px) !important;
	}

	orbit-modal-base {
		ion-header:first-of-type {
			padding-top: 0;
		}
	}

	// .modal {
	// 	&-fullscreen {
	// 	}

	// 	&-transparent {
	// 	}
	// }
}

.base-app-body {
	border-radius: 15px;
}

.base-modal-card {
	padding-bottom: 42px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	overflow-y: scroll;
	overflow-x: hidden;
}

// .safe-area {
// 	&-vertical {
// 		// margin-top: $safeareaInsetTop;
// 		// margin-bottom: $safeareaInsetBottom;
// 	}
// }

.header-ios ion-toolbar:last-of-type {
	--border-width: 0 0 0px;
}

ion-tab-bar {
	padding: 0 !important;
}

ion-header ion-toolbar:first-of-type {
	padding-top: 0 !important;
	--min-height: 52px;
}

ion-header.orbit-header {
	-webkit-box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0);
	box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0);

	&.header-collapse-condense-inactive {
		-webkit-box-shadow: unset;
		box-shadow: unset;
	}
}

ion-header[collapse="condense"].orbit-header {
	background-color: #00000000 !important;
	-webkit-box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0);
	box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0);

	ion-toolbar > ion-title {
		padding: 3px 16px 12px 16px !important;
		font-size: 24px !important;
		font-weight: 850 !important;
		text-align: left;
	}
}

.header-sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 0px;

	&.has-background {
		background-color: rgba(var(--ion-color-ultra-light-rgb), 0.9);

		div {
			background-color: rgba(var(--ion-color-ultra-light-rgb), 0.9);
		}
	}
}

.bottom-sticky {
	position: sticky;
	position: -webkit-sticky;
	bottom: 0px;
}

ion-card {
	box-shadow: none;
}

ion-item {
	--border-color: var(--ion-color-lighter);
	// --border-radius: 8px;
}

// ion-searchbar {
//   --background: var(--ion-items-background-color) !important;
//   --border-radius: 100px !important;
//   --box-shadow: 1px !important;
//   --cancel-button-color: var(--ion-text-color-alt) !important;
//   --clear-button-color: var(--ion-text-color-alt) !important;
//   --color: var(--ion-color-dark) !important;
//   --icon-color: var(--ion-color-dark) !important;
//   --placeholder-color: var(--ion-text-color-alt) !important;
//   --placeholder-font-style: initial !important;
//   --placeholder-font-weight: initial !important;
//   --placeholder-opacity: 0.8 !important;

//   &.custom {
//     .searchbar-input-container {
//       height: 45px !important;
//     }

//     .searchbar-input {
//       padding-inline-start: 32px !important;
//     }

//     .searchbar-search-icon {
//       margin-inline-start: 6px !important;
//       width: 18px;
//     }
//   }
// }

ion-searchbar {
	// --background: var(--ion-color-ultra-light) !important;
	--border-radius: 100px !important;
	--box-shadow: 1px !important;
	--cancel-button-color: var(--ion-color-dark) !important;
	--clear-button-color: var(--ion-color-dark) !important;
	--color: var(--ion-color-dark) !important;
	--icon-color: var(--ion-color-medium) !important;
	--placeholder-color: var(--ion-color-medium) !important;
	--placeholder-font-style: initial !important;
	--placeholder-font-weight: 500 !important; //400
	--placeholder-opacity: 0.8 !important;
	padding-bottom: 10px;

	.searchbar-input-container {
		height: 100% !important;
		// background: var(--ion-color-ultra-light) !important;
	}

	.searchbar-input {
		padding-inline-start: 40px !important;
		// background: var(--ion-color-ultra-light) !important;
	}

	.searchbar-search-icon {
		margin-inline-start: 2px !important;
		margin-top: 2px;
		width: 1rem !important;
		// height: 1rem !important;
	}
}

ion-segment {
	--background: var(--ion-card-background);
}

ion-segment-button {
	font-size: 14px;
	line-height: 14px;
	--padding-bottom: 1px;
	--padding-top: 1px;
	margin-top: 0;
	margin-bottom: 0;
	min-height: 0px;
	--background-checked: var(--ion-color-overlay);
	--background-focused: var(--ion-color-overlay);
	--background-focused-opacity: 1;
	--background-hover: var(--ion-color-overlay);
	--background-hover-opacity: 1;
	--border-radius: 8px;
	--border-style: solid;
	--border-width: 0px;
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary-contrast);
	--color-focused: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary-contrast);
	--indicator-color: var(--ion-color-primary);
	--transition: background-color 15ms linear;
}

.custom-segment {
	border-radius: 8px !important;
	--background: var(--ion-color-background);
	border: 1px solid var(--ion-color-overlay);
	padding: 4px;

	ion-segment-button {
		font-size: 14px;
		line-height: 14px;
		--padding-bottom: 1px;
		--padding-top: 1px;
		margin-top: 0;
		margin-bottom: 0;
		--background-checked: var(--ion-color-overlay);
		--background-focused: var(--ion-color-overlay);
		--background-focused-opacity: 1;
		--background-hover: var(--ion-color-overlay);
		--background-hover-opacity: 1;
		--border-radius: 8px;
		--border-style: solid;
		--border-width: 0px;
		min-height: 0px;
		--color: var(--ion-text-color);
		--color-checked: var(--ion-color-primary-contrast);
		--color-focused: var(--ion-color-primary-contrast);
		--color-hover: var(--ion-color-primary-contrast);
		--indicator-color: var(--ion-color-primary);
	}
}

ion-chip[color="primary"] {
	background: var(--ion-color-primary) !important;
	color: var(--ion-color-primary-contrast) !important;
}

ion-chip[color="ultra-light"] {
	background: var(--ion-color-ultra-light) !important;
	color: var(--ion-color-ultra-light-contrast) !important;
}

ion-chip[solid] {
	background: var(--ion-color-base) !important;
	color: var(--ion-color-contrast) !important;
}

ion-fab-button[fill="clear"] {
	// --background: #00000000 !important;
	// --background-activated: #00000000 !important;
	// --background-hover: #00000000 !important;
	--border-radius: 100% !important;
	--box-shadow: unset !important;
	color: var(--ion-color-base) !important;

	--background: #00000000 !important;
	background: #00000000 !important;
	--background-activated: #00000000 !important;
	--background-activated-opacity: 1 !important;
	--background-focused: #00000000 !important;
	--background-focused-opacity: 1 !important;
	--background-hover: #00000000 !important;
	--background-hover-opacity: 1 !important;
	--border-color: #00000000 !important;
	// --border-radius: 8px;
	// --border-style: solid;

	--color: var(--ion-color-base) !important;
	--color-activated: var(--ion-color-shade) !important;
	--color-focused: var(--ion-color-shade) !important;
	--color-hover: var(--ion-color-shade) !important;

	// --padding-bottom: 4px;
	// --padding-end: 4px;
	// --padding-start: 4px;
	// --padding-top: 4px;
	// --ripple-color: #1e1e1e;
	// --transition: background-color 15ms linear;
	.button-native {
		background: #00000000 !important;
		color: var(--ion-color-base) !important;
	}
}

ion-button {
	font-size: 16px;
	font-weight: 800;
	line-height: 160%;

	&.button-disabled {
		opacity: 70% !important;
	}
}

ion-button:not([size]) {
	min-height: 56px;
}

ion-button[size="medium"] {
	height: 35px;
}


ion-button:not([expand]) {
	.button-native {
		padding: 8px 14px;
	}
}

ion-input {
	--highlight-color-focused: purple;
}

ion-toast.orbit-custom {
	--min-height: 56px;
	--border-radius: 8px;
	--start: 16px;
	--end: 16px;
	--box-shadow: none;
}
ion-toast.tab-toast {
	::ng-deep {
		.toast-wrapper.toast-bottom {
			margin-bottom: 75px !important;
		}
	}
}

// ion-toast.tab-page-toast {
// }

// ion-toast.tab-page-toast::part(message) {
// }

// ion-toast.tab-page-toast::part(button) {
// }

.custom-back-button {
	// border-radius: 8px;
	// border: 1px solid var(--ion-color-medium-light);
	// width: 39px;
	// height: 39px;
	// max-height: 38px;
	// max-width: 38px;
	margin: 0px;

	::part("native") {
		padding: 0px;
	}

	.custom-back-icon {
		height: 39px;
		width: 39px;
	}
}

ion-item[details="opaque"] {
	--detail-icon-opacity: 1;
}

ion-list {
	ion-item {
		&:last-of-type {
			--border-color: #3880ff00 !important;
			// --border-radius: 8px;
			// --border-style: solid;
			--border-width: 0px !important;
		}
	}
}

*[shape="round"] {
	overflow: hidden;
	--border-radius: 8px;
	border-radius: 8px;
}

*[shape="circle"] {
	&:has(> ion-icon[slot="icon-only"]) {
		--padding-bottom: 0px;
		--padding-end: 0px;
		--padding-start: 0px;
		--padding-top: 0px;

		&::part(native) {
			padding: 0;
		}

		// width:55px;
		// height: 55px;
	}

	overflow: hidden;
	--border-radius: 100px !important;
	border-radius: 100px !important;
}

*[shape="square"] {
	overflow: hidden;
	--border-radius: 4px !important;
	border-radius: 4px !important;
}

*[bg="none"] {
	--background: #00000000 !important;
	background-color: #00000000 !important;
}

*[bg="ultra-light"] {
	--background: var(--ion-color-ultra-light) !important;
	background-color: var(--ion-color-ultra-light) !important;
}

*[bg="warning"] {
	--background: var(--ion-color-warning) !important;
	background-color: var(--ion-color-warning) !important;
}

*[bg="black"] {
	--background: var(--ion-color-black) !important;
	background-color: var(--ion-color-black) !important;
}

*[role="listitem"]:last-of-type {
	border-bottom: unset;
	--border-color: unset;
}

*[text-transform="none"] {
	text-transform: none;
}

*[text-transform="capital"] {
	text-transform: capitalize;
}

*[disabled="true"][opacity="true"] {
	opacity: 0.5;
}

ion-icon[size="medium"] {
	font-size: x-large;
}

[size="xsmall"] {
	height: 25px;
	width: 25px;
}

[size="xxsmall"] {
	height: 22px;
	width: 22px;
}

[fill="half-solid"] {
	color: var(--ion-color-base);
	--background: rgba(var(--ion-color-base-rgb), 0.2);
}

.unset-box-shadow {
	--box-shadow: unset;
}

.ion-item-dark-border {
	--border-color: rgba(var(--ion-color-dark-rgb), 0.25);
}

.custom-item-detail-icon {
	--detail-icon-color: var(--ion-text-color) !important;
	--detail-icon-font-size: 20px !important;
	--detail-icon-opacity: 1 !important;
}

.full {
	&-height {
		height: 100%;

		&.max {
			max-width: 100%;
		}
	}

	&-width {
		width: 100%;

		&.max {
			max-width: 100%;
		}
	}
}

.max-height-98 {
	max-height: 98%;
}

.custom {
	&-ion {
		&-card {
			border-radius: 10px 10px 10px 10px;
		}

		&-item::part(native) {
			height: 55px;
		}
	}
}

.modal {
	--max-height: 95vh;

	&-fullscreen {
		--max-height: 100vh;
		--height: 100vh;
		--width: 100%;
		--border-radius: 0;
		--backdrop-opacity: 0.1 !important;
		--background: rgba(0, 0, 0, 0);
	}

	&-transparent {
		--max-height: 100vh;
		--height: 100vh;
		--width: 100%;
		--border-radius: 0;
		--backdrop-opacity: 0;
		--background: rgba(0, 0, 0, 0);
	}

	@for $i from 100 through 80 {
		&-height-#{$i} {
			--height: #{$i + "%"} !important;
			--min-height: #{$i + "%"};
		}
	}

	&-height {
		&-60 {
			// --height: 60% !important;
			--min-height: 20%;
		}
	}

	&-position {
		&-bottom {
			align-items: flex-end;
		}

		&-center {
			--min-height: 10%;
			align-items: center;
			flex: 1;
			flex-shrink: 1;
			display: flex;
			justify-content: center;
		}
	}

	&-radius {
		--border-radius: 25px 25px 0 0;
		// .modal-wrapper {
		//     border-radius: 25px !important;
		// }
	}

	&-onboarding {
		z-index: 30000 !important;
		--max-height: 100vh;
		--height: 100%;
		--width: 100%;
		--border-radius: 0;
	}

	// &-auth {
	// 	// z-index: 30001 !important;
	// 	// --backdrop-opacity: 0.3 !important;
	// }

	&-lang {
		z-index: 30003 !important;
		--max-height: 100vh;
		--height: 100%;
		--width: 100%;
		--border-radius: 0;
		--background: rgba(0, 0, 0, 0);
	}

	&-agreements {
		z-index: 30003 !important;
	}

	&-alert {
		z-index: 30008 !important;
	}

	&-networkalert {
		z-index: 100000 !important;
	}
}

.bg {
	&-default {
		--background: #00000000;
		background-color: #00000000;
		-webkit-transition: --background 100ms linear;
		-ms-transition: --background 100ms linear;
		transition: --background 100ms linear;
		-webkit-transition: background-color 250ms linear;
		-ms-transition: background-color 250ms linear;
		transition: background-color 250ms linear;
	}

	&-opaque {
		background-color: #00000040;
		--background: #00000040;
		-webkit-transition: background-color 250ms linear;
		-ms-transition: background-color 250ms linear;
		transition: background-color 250ms linear;
	}
}

.modal-card-area {
	// padding-bottom: calc(
	//     16px + var(--ion-safe-area-bottom) + var(--ion-safe-area-top)
	// ); //burada +30px vardı. kaldırdıms
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.break-spaces {
	white-space: break-spaces !important;
}

.text {
	&-nowrap {
		white-space: nowrap;
	}
	&-hide-oneline {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

/**
    margin, padding
*/
$base-value: 5;

@for $i from 8 through 20 {
	$value: $base-value * $i;

	.p-all-#{$value} {
		padding: #{$value}px;

		&-ion {
			--padding-bottom: #{$value}px !important;
			--padding-end: #{$value}px !important;
			--padding-start: #{$value}px !important;
			--padding-top: #{$value}px !important;
		}

		&-inner {
			--inner-padding-top: #{$value}px !important;
			--inner-padding-end: #{$value}px !important;
			--inner-padding-start: #{$value}px !important;
			--inner-padding-bottom: #{$value}px !important;
		}
	}

	.p-horizontal-#{$value} {
		padding-left: #{$value}px;
		padding-right: #{$value}px;

		&-ion {
			--padding-end: #{$value}px !important;
			--padding-start: #{$value}px !important;
		}

		&-inner {
			--inner-padding-end: #{$value}px !important;
			--inner-padding-start: #{$value}px !important;
		}
	}

	.p-vertical-#{$value} {
		padding-top: #{$value}px;
		padding-bottom: #{$value}px;

		&-ion {
			--padding-bottom: #{$value}px !important;
			--padding-top: #{$value}px !important;
		}

		&-inner {
			--inner-padding-top: #{$value}px !important;
			--inner-padding-bottom: #{$value}px !important;
		}
	}

	.p-top-#{$value} {
		padding-top: #{$value}px;

		&-ion {
			--padding-top: #{$value}px !important;
		}

		&-inner {
			--inner-padding-top: #{$value}px !important;
		}
	}

	.p-bottom-#{$value} {
		padding-bottom: #{$value}px;

		&-ion {
			--padding-bottom: #{$value}px !important;
		}

		&-inner {
			--inner-padding-bottom: #{$value}px !important;
		}
	}

	.p-left-#{$value} {
		padding-left: #{$value}px;

		&-ion {
			--padding-start: #{$value}px !important;
		}

		&-inner {
			--inner-padding-start: #{$value}px !important;
		}
	}

	.p-right-#{$value} {
		padding-right: #{$value}px;

		&-ion {
			--padding-end: #{$value}px !important;
		}

		&-inner {
			--inner-padding-end: #{$value}px !important;
		}
	}

	.m-all-#{$value} {
		margin: #{$value}px;
	}

	.m-horizontal-#{$value} {
		margin-left: #{$value}px;
		margin-right: #{$value}px;
	}

	.m-vertical-#{$value} {
		margin-top: #{$value}px;
		margin-bottom: #{$value}px;
	}

	.m-top-#{$value} {
		margin-top: #{$value}px;
	}

	.m-bottom-#{$value} {
		margin-bottom: #{$value}px;
	}

	.m-left-#{$value} {
		margin-left: #{$value}px;
	}

	.m-right-#{$value} {
		margin-right: #{$value}px;
	}
}

@for $i from 0 through 40 {
	$value: $i;

	.p-all-#{$value} {
		padding: #{$value}px;

		&-i {
			padding: #{$value}px !important;
		}

		&-ion {
			--padding-bottom: #{$value}px;
			--padding-end: #{$value}px;
			--padding-start: #{$value}px;
			--padding-top: #{$value}px;
		}

		&-inner {
			--inner-padding-top: #{$value}px !important;
			--inner-padding-end: #{$value}px !important;
			--inner-padding-start: #{$value}px !important;
			--inner-padding-bottom: #{$value}px !important;
		}
	}

	.p-horizontal-#{$value} {
		padding-left: #{$value}px;
		padding-right: #{$value}px;

		&-i {
			padding-left: #{$value}px !important;
			padding-right: #{$value}px !important;
		}

		&-inline {
			padding-inline-start: #{$value}px;
			padding-inline-end: #{$value}px;
		}

		&-ion {
			--padding-end: #{$value}px;
			--padding-start: #{$value}px;
		}

		&-inner {
			--inner-padding-end: #{$value}px !important;
			--inner-padding-start: #{$value}px !important;
		}
	}

	.p-vertical-#{$value} {
		padding-top: #{$value}px;
		padding-bottom: #{$value}px;

		&-i {
			padding-top: #{$value}px !important;
			padding-bottom: #{$value}px !important;
		}

		&-ion {
			--padding-bottom: #{$value}px;
			--padding-top: #{$value}px;
		}

		&-inner {
			--inner-padding-top: #{$value}px !important;
			--inner-padding-bottom: #{$value}px !important;
		}
	}

	.p-top-#{$value} {
		padding-top: #{$value}px;

		&-i {
			padding-top: #{$value}px !important;
		}

		&-ion {
			--padding-top: #{$value}px;
		}

		&-inner {
			--inner-padding-top: #{$value}px !important;
		}
	}

	.p-bottom-#{$value} {
		padding-bottom: #{$value}px;

		&-i {
			padding-bottom: #{$value}px !important;
		}

		&-ion {
			--padding-bottom: #{$value}px;
		}

		&-inner {
			--inner-padding-bottom: #{$value}px !important;
		}
	}

	.p-left-#{$value} {
		padding-left: #{$value}px;

		&-i {
			padding-left: #{$value}px !important;
		}

		&-inline {
			padding-inline-start: #{$value}px;
		}

		&-ion {
			--padding-start: #{$value}px;
		}

		&-inner {
			--inner-padding-start: #{$value}px !important;
		}
	}

	.p-right-#{$value} {
		padding-right: #{$value}px;

		&-i {
			padding-right: #{$value}px !important;
		}

		&-inline {
			padding-inline-end: #{$value}px;
		}

		&-ion {
			--padding-end: #{$value}px;
		}

		&-inner {
			--inner-padding-end: #{$value}px !important;
		}
	}

	.m-all-#{$value} {
		margin: #{$value}px;

		&-i {
			margin: #{$value}px !important;
		}
	}

	.m-horizontal-#{$value} {
		margin-left: #{$value}px;
		margin-right: #{$value}px;

		&-i {
			margin-left: #{$value}px !important;
			margin-right: #{$value}px !important;
		}

		&-inline {
			margin-inline-start: #{$value}px !important;
			margin-inline-end: #{$value}px !important;
		}
	}

	.m-vertical-#{$value} {
		margin-top: #{$value}px;
		margin-bottom: #{$value}px;

		&-i {
			margin-top: #{$value}px !important;
			margin-bottom: #{$value}px !important;
		}
	}

	.m-top-#{$value} {
		margin-top: #{$value}px;

		&-i {
			margin-top: #{$value}px !important;
		}
	}

	.m-bottom-#{$value} {
		margin-bottom: #{$value}px;

		&-i {
			margin-bottom: #{$value}px !important;
		}
	}

	.m-left-#{$value} {
		margin-left: #{$value}px;

		&-i {
			margin-left: #{$value}px !important;
		}

		&-inline {
			margin-inline-start: #{$value}px;
		}
	}

	.m-right-#{$value} {
		margin-right: #{$value}px;

		&-i {
			margin-right: #{$value}px !important;
		}

		&-inline {
			margin-inline-end: #{$value}px;
		}
	}
}

@for $i from 1 through 100 {
	$value: $i * 5;

	.height {
		&-#{$value} {
			&::part(native) {
				height: #{$value}px;
			}

			height: #{$value}px;

			&-percent {
				&::part(native) {
					height: #{$value + "%"};
				}

				height: #{$value + "%"};
			}

			&-ion {
				--height: #{$value}px;

				&-percent {
					--height: #{$value + "%"};
				}
			}
		}

		&-min-#{$value} {
			min-height: #{$value}px;
			height: #{$value}px;

			&-ion {
				--min-height: #{$value}px;
				--height: #{$value}px;
			}
		}

		&-max-#{$value} {
			max-height: #{$value}px;
			height: #{$value}px;

			&-ion {
				--msx-height: #{$value}px;
				--height: #{$value}px;
			}
		}
	}

	.width {
		&-#{$value} {
			&::part(native) {
				width: #{$value}px;
			}

			width: #{$value}px;

			&-percent {
				&::part(native) {
					width: #{$value + "%"};
				}

				width: #{$value + "%"};
			}

			&-ion {
				--width: #{$value}px;

				&-percent {
					--width: #{$value + "%"};
				}
			}
		}

		&-min-#{$value} {
			min-width: #{$value}px;
			width: #{$value}px;

			&-ion {
				--min-width: #{$value}px;
				--width: #{$value}px;
			}
		}

		&-max-#{$value} {
			max-width: #{$value}px;
			width: #{$value}px;

			&-ion {
				--max-width: #{$value}px;
				--width: #{$value}px;
			}
		}
	}

	.height {
		&-#{$i} {
			&::part(native) {
				height: #{$i}px;
			}

			height: #{$i}px;

			&-percent {
				&::part(native) {
					height: #{$i + "%"};
				}

				height: #{$i + "%"};
			}

			&-ion {
				--height: #{$i}px;

				&-percent {
					--height: #{$i + "%"};
				}
			}
		}

		&-min-#{$i} {
			min-height: #{$i}px;
			height: #{$i}px;

			&-ion {
				--min-height: #{$i}px;
				--height: #{$i}px;
			}
		}

		&-max-#{$i} {
			max-height: #{$i}px;
			height: #{$i}px;

			&-ion {
				--msx-height: #{$i}px;
				--height: #{$i}px;
			}
		}
	}

	.width {
		&-#{$i} {
			&::part(native) {
				width: #{$i}px;
			}

			width: #{$i}px;

			&-percent {
				&::part(native) {
					width: #{$i + "%"};
				}

				width: #{$i + "%"};
			}

			&-ion {
				--width: #{$i}px;

				&-percent {
					--width: #{$i + "%"};
				}
			}
		}

		&-min-#{$i} {
			min-width: #{$i}px;
			width: #{$i}px;

			&-ion {
				--min-width: #{$i}px;
				--width: #{$i}px;
			}
		}

		&-max-#{$i} {
			max-width: #{$i}px;
			width: #{$i}px;

			&-ion {
				--max-width: #{$i}px;
				--width: #{$i}px;
			}
		}
	}
}

.m-auto {
	margin: auto;
}

.d-block {
	display: block;
}

.d-inline-flex {
	display: inline-flex;
}

.d-flex {
	display: flex;

	&-i {
		display: flex !important;
	}
}

.d-flex,
.d-flex-i {
	flex-direction: column;

	&.row {
		flex-direction: row;

		&-reverse {
			flex-direction: row-reverse;
		}
	}

	&.column {
		flex-direction: column;

		&-reverse {
			flex-direction: column-reverse;
		}
	}

	&.wrap {
		flex-wrap: wrap;
	}

	&.gap {
		gap: 16px;

		@for $i from 1 through 24 {
			&-#{$i * 2} {
				gap: #{$i * 2}px;
			}
		}
	}

	&.align {
		&-center {
			align-items: center;
		}

		&-end {
			align-items: flex-end;
		}
	}

	&.justify {
		&-center {
			justify-content: center;
		}

		&-between {
			justify-content: space-between;
		}

		&-around {
			justify-content: space-around;
		}

		&-evenly {
			justify-content: space-evenly;
		}

		&-start {
			justify-content: flex-start;
		}

		&-end {
			justify-content: flex-end;
		}
	}

	.flex {
		&-grow {
			flex-grow: 1;
		}

		&-shrink {
			flex-shrink: 1;
		}

		&-1 {
			flex: 1;
		}

		&-2 {
			flex: 2;
		}

		&-3 {
			flex: 3;
		}

		&-4 {
			flex: 4;
		}
	}
}

@for $i from 1 through 24 {
	.gap-#{$i * 2} {
		gap: #{$i * 2}px;
	}
}

.d-contents {
	display: contents !important;
}

.d-grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);

	&.col-3 {
		grid-template-columns: repeat(3, 1fr);
	}
}

/**
    margin, padding - end
*/

/**
    font-size
*/
@for $i from 0 through 64 {
	.font-size-#{$i} {
		font-size: #{$i}px;
		line-height: #{$i * 1.5}px;
		// letter-spacing: -0.03em;

		&-i {
			font-size: #{$i}px !important;
			line-height: #{$i * 1.5}px !important;

			// letter-spacing: -0.1em;
			&-line-100,
			&.line-100 {
				font-size: #{$i}px !important;
				line-height: #{$i * 1}px !important;
			}
		}
	}
}

/**
    font-size end
*/

/**
    font-weight
*/
$count: 300;

@while $count < 1000 {
	.font-weight-#{$count} {
		/* burada daha sonradan tüm uygulamadaki font-weightler arttırılmak istendiği için
            class name ile value arasında 200 birimlik bir fark var. bir noktada tüm projede
            classlar düzenlenirse bu toplama işlemi geri alınabilir.
        */
		// $value: $count + 200;
		font-weight: ($count);

		&-i {
			font-weight: ($count) !important;
		}
	}

	$count: $count + 50;
}

/**
   değerler yüzdelik olarak verilmiştir. 
*/
.line-height {
	&-100 {
		line-height: 100%;

		&-i {
			line-height: 100% !important;
		}
	}

	&-150 {
		line-height: 150%;

		&-i {
			line-height: 150% !important;
		}
	}

	&-200 {
		line-height: 200%;

		&-i {
			line-height: 200% !important;
		}
	}
}

/**
    font-weight end
*/
.border {
	&-basic {
		border: 1px solid var(--ion-color-overlay);
		&-bottom {
			border-bottom: 1px solid var(--ion-color-overlay);
		}
	}

	&-primary {
		border-color: var(--ion-color-primary);
	}

	&-radius {
		&-full {
			&-8 {
				border-radius: 8px;
			}

			&-24 {
				border-radius: 24px;
			}
		}

		&-top {
			&-none {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}

		&-bottom {
			border: 1px solid var(--ion-color-overlay);

			&-none {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}

.position {
	&-top {
		position: fixed;
		top: calc(20px + var(--ion-safe-area-top));
		left: 0;

		&-10 {
			position: fixed;
			top: calc(10px + var(--ion-safe-area-top));
			left: 0;
		}

		&-end {
			position: fixed;
			top: calc(20px + var(--ion-safe-area-top));
			right: 20px;

			&-10 {
				position: fixed;
				top: calc(10px + var(--ion-safe-area-top));
				right: 10px;
			}
		}

		&-start {
			position: fixed;
			top: calc(20px + var(--ion-safe-area-top));
			left: 20px;

			&-10 {
				position: fixed;
				top: calc(10px + var(--ion-safe-area-top));
				left: 10px;
			}
		}
	}

	&-bottom {
		position: fixed;
		bottom: calc(20px + var(--ion-safe-area-bottom));
		left: 0;

		&-end {
			position: fixed;
			bottom: calc(20px + var(--ion-safe-area-bottom));
			right: 20px;
		}

		&-start {
			position: fixed;
			bottom: calc(20px + var(--ion-safe-area-bottom));
			left: 20px;
		}
	}

	&-relative {
		position: relative;
	}

	&-absolute {
		position: absolute;

		&-top {
			position: absolute;
			// top: calc(20px + var(--ion-safe-area-top));
			top: 20px;

			&-end {
				position: absolute;
				top: 20px;
				right: 20px;
			}
		}

		&-bottom {
			position: absolute;
			bottom: 20px;

			&-end {
				position: absolute;
				bottom: 20px;
				right: 20px;
			}
		}
	}
}

/**
    z-index
*/
$base-z-index: 10;

@for $i from 0 through 100 {
	$value: $i * $base-z-index;

	.z-index-#{$value} {
		z-index: $value;
	}
}

@for $i from 1 through 20 {
	.z-index-#{$i} {
		z-index: $i;
	}
}

/**
    z-index - end
*/

.arpa-input-item-old {
	--min-height: 54px !important;
	--max-height: 54px !important;
	height: 54px !important;
	--inner-padding-end: 16px !important;

	&::part(native) {
		--min-height: 54px !important;
		--max-height: 54px !important;
		height: 54px !important;
	}

	ion-input {
		font-size: 14px !important;
		font-weight: 600 !important;
		--color: var(--ion-color-dark);
		--placeholder-color: var(--ion-color-medium-light);
		--highlight-color-focused: var(--ion-color-primary);
		min-height: 25px !important;
		height: 25px !important;

		.native-input {
			color: var(--ion-color-dark);
			padding: 0;
			margin-top: -12px;
			--padding-bottom: 0;
			--padding-top: 0;
		}

		.label-text {
			color: var(--ion-color-medium-light);
			font-size: 12px;
			font-weight: 500;
		}
	}
}

.ion-item-invalid {
	border: 1px solid var(--ion-color-danger);
	--background: #fff4f2; //rgba(var(--ion-color-danger-rgb), .05)
}

// .item-has-focus,
// .item-has-value {
//     ion-label {
//         margin-top: 10px !important;
//     }
// }
.form-error {
	font-style: normal;
	font-weight: 700; //500;
	font-size: 12px;
	line-height: 20px;
	color: var(--ion-color-danger);
	display: inline-block;
}

.ion-item-invalid {
	border: 1px solid var(--ion-color-danger);
	--background: #fff4f2; //rgba(var(--ion-color-danger-rgb), .05)
}

.item-has-focus,
.item-has-value {
	.form-label {
		margin-top: 0px;
		//     font-size: 12px;
		//     font-weight: 800; // 600;
		//     line-height: 20px;
		//     letter-spacing: 0px;
		//     text-align: left;
		//     // --color: #a9a9a9 !important;
		//     // color: #a9a9a9 !important;
		//     transform: none;
		//     margin-top: 4px !important;
		//     margin-bottom: -4px !important;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.form-label {
	margin-top: -12px;
	font-size: 12px !important;
	font-weight: 800 !important; // 600;
	line-height: 20px !important;
	letter-spacing: 0px;
	text-align: left;
	--color: #a9a9a9 !important;
	color: #a9a9a9 !important;
}

.item-height-61 {
	--min-height: 55px !important;
	--max-height: 55px !important;
	height: 55px !important;
	--inner-padding-end: 0px !important;

	ion-input {
		font-size: 14px !important;
		font-weight: 700 !important;
		--color: var(--ion-color-dark);
		--placeholder-color: var(--ion-color-medium-light);
		--highlight-color-focused: var(--ion-color-primary);

		.native-input {
			color: var(--ion-color-dark);
			padding: 0;
			margin-top: -10px;
		}
	}
}

.divider {
	width: 100%;
	height: 2px;
	border-radius: 100%;

	&.thin {
		height: 1px;
	}

	&.thinner {
		height: 0.1px;
		opacity: 0.5;
	}

	&-darker {
		background-color: var(--ion-color-darker);
	}

	&-dark {
		background-color: var(--ion-color-dark);
	}

	&-medium {
		background-color: var(--ion-color-medium);
	}

	&-light {
		background-color: var(--ion-color-light);
	}

	&-lighter {
		background-color: var(--ion-color-lighter);
	}

	&-dots {
		border-bottom: 2px dashed var(--ion-color-tertiary);
		width: calc(100% - 32px);
		margin: 16px;
		border-radius: 10px;
	}
}

.text-color {
	&-primary {
		color: var(--ion-color-primary);
	}
}

span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	&[color="primary"] {
		color: var(--ion-color-primary);
	}

	&[color="secondary"] {
		color: var(--ion-color-secondary);
	}

	&[color="tertiary"] {
		color: var(--ion-color-tertiary);
	}

	&[color="success"] {
		color: var(--ion-color-success);
	}

	&[color="warning"] {
		color: var(--ion-color-warning);
	}

	&[color="danger"] {
		color: var(--ion-color-danger);
	}

	&[color="dark"] {
		color: var(--ion-color-dark);
	}

	&[color="medium-dark"] {
		color: var(--ion-color-medium-dark);
	}

	&[color="medium"] {
		color: var(--ion-color-medium);
	}

	&[color="medium-light"] {
		color: var(--ion-color-medium-light);
	}

	&[color="light"] {
		color: var(--ion-color-light);
	}

	&[color="white"] {
		color: var(--ion-color-white);
	}

	&[color="black"] {
		color: var(--ion-color-black);
	}

	&[color="addition-1"] {
		color: var(--ion-color-addition-1);
	}

	&[color="addition-2"] {
		color: var(--ion-color-addition-2);
	}

	&[color="addition-3"] {
		color: var(--ion-color-addition-3);
	}

	&[color="addition-4"] {
		color: var(--ion-color-addition-4);
	}

	&[color="addition-5"] {
		color: var(--ion-color-addition-5);
	}
}

.farm-avatar {
	background-color: var(--ion-color-light);
	--border-radius: 0px;
	height: 42px;
	width: 42px;
	margin-top: 0;
	margin-bottom: 0;
	border-radius: 10px;
}

.farm-svg {
	border: 5px solid var(--ion-color-light);
	border-radius: 10px;
	background-color: var(--ion-color-light);
	fill: rgba(var(--ion-color-tertiary-rgb), 1);
	width: 100%;
	height: 100%;
	stroke: var(--ion-color-tertiary);
	stroke-width: 10px;
}

.hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 1s linear;

	&-open {
		visibility: visible;
		opacity: 1;
	}
}

.ng-otp-input-wrapper {
	text-align: center !important;
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.otp-input {
	height: 83px !important;
	flex: 1;
	max-width: 83px !important;
	font-size: 30px !important;
	font-weight: 700;
	background-color: #ffffff;
	border: 1px solid #eeeeee !important;
	border-radius: 10px !important;

	// box-shadow: 0px 3px 0px rgba(141, 153, 174, 0.5);
	&:focus,
	&:active {
		outline-style: none !important;
		border-top: 1px solid #eeeeee !important;
		border-right: 1px solid #eeeeee !important;
		border-left: 1px solid #eeeeee !important;
		border-bottom: 2px solid var(--ion-color-secondary) !important;
	}
}

.popover {
	ion-backdrop {
		--backdrop-opacity: 0.6;
	}

	&-no-bg {
		--background: rgba(0, 0, 0, 0);
		--box-shadow: unset;
	}

	&-small {
		--width: 30%;

		&.center {
			& .popover-content {
				--offset-x: calc(35% - 5px);
			}
		}
	}

	&-medium {
		--width: 60%;

		&.center {
			--offset-x: calc(20% - 5px);
		}
	}

	&-large {
		--width: 90%;

		&.center {
			--offset-x: calc(5% - 5px);
		}
	}
}

.placeholder-color {
	&-dark {
		--placeholder-color: var(--ion-color-dark) !important;
	}
}

.object-fit-cover {
	object-fit: cover;
}

.width {
	&-px {
		&-60 {
			width: 60px;
		}
	}
}

.overflow {
	&-lock {
		overflow: hidden;
	}

	&-scroll {
		overflow: scroll;
	}
}

.text-align {
	&-center {
		text-align: center;
	}
}

.loader-animation {
	background-image: url(../assets/images/spinner_first_step.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 50px;
	min-height: 50px;
	min-width: 50px;
	padding: 16px;
	-webkit-animation: spin 3000ms linear infinite; //, moveLeftToRight 5s linear infinite;
	-moz-animation: spin 3000ms linear infinite; //, moveLeftToRight 5s linear infinite;
	-ms-animation: spin 3000ms linear infinite; //, moveLeftToRight 5s linear infinite;
	animation: spin 3000ms linear infinite; //, moveLeftToRight 5s linear infinite;

	-webkit-transition: all 1s ease;
	transition: all 1s ease;

	// position: absolute;
	// left: 0;
}

/* Spinning the sphere using key frames */
@-ms-keyframes spin {
	from {
		-ms-transform: rotate(360deg);
	}

	to {
		-ms-transform: rotate(0deg);
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(360deg);
	}

	to {
		-moz-transform: rotate(0deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(360deg);
	}

	to {
		transform: rotate(0deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(360deg);
	}

	to {
		-webkit-transform: rotate(0deg);
	}
}

//   /* Move sphere from left to right */
//   @-moz-keyframes moveLeftToRight {
//     0%   { left: -100px; }
//     100% { left: 100%; }
//   }
//   @-ms-keyframes moveLeftToRight {
//     0%   { left: -100px; }
//     100% { left: 100%; }
//   }
//   @keyframes moveLeftToRight {
//     0%   { left: -100px; }
//     100% { left: 100%; }
//   }
//   @-webkit-keyframes moveLeftToRight {
//     0%   { left: -100px; }
//     100% { left: 100%; }
//   }
.custom-list-items {
	list-style-type: none;
	padding-left: 0;

	li {
		margin-bottom: 16px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.farm-drawing-marker {
	// background-size: contain;
	width: 18px;
	height: 18px;
	background-color: var(--ion-color-light);
	border-radius: 100%;

	// background-position: top;
	// background-repeat: no-repeat;
	// background-image: url(../assets/icon/pins/white-ellipse.png);
	// background-position: bottom;
	&.first-marker {
		background-color: var(--ion-color-primary);
	}

	&:first-of-type {
		background-color: var(--ion-color-primary);
	}
}

.filter {
	&-blur {
		filter: blur(1px);

		&-2 {
			filter: blur(2px);
		}
	}
}

.no-pointer {
	pointer-events: none;
}

@for $i from 1 through 20 {
	.m-minus {
		&-bottom-#{$i} {
			margin-bottom: #{$i * -1}px !important;
		}

		&-left-#{$i} {
			margin-left: #{$i * -1}px !important;
		}
		&-horizontal-#{$i} {
			margin-left: #{$i * -1}px !important;
			margin-right: #{$i * -1}px !important;
		}
	}
}

.text-decoration {
	&-underline {
		text-decoration: underline;
	}

	&-italic {
		font-style: italic;
	}
}

// ::ng-deep {
ion-title.ios:not(.custom) {
	padding-left: 45px !important;
	padding-right: 45px !important;

	.toolbar-title {
		white-space: unset !important;
		word-wrap: break-word !important;
		text-overflow: unset !important;
	}
}

.top {
	&-0 {
		top: 0;
	}

	&-10 {
		top: 10px;
	}

	&-minus {
		&-0 {
			top: 0;
		}

		&-7 {
			top: -7px;
		}

		&-10 {
			top: -10px;
		}

		&-15 {
			top: -15px;
		}
	}
}

.bottom {
	&-0 {
		bottom: 0;
	}

	&-minus {
		&-0 {
			bottom: 0;
		}

		&-10 {
			bottom: -10px;
		}

		&-15 {
			bottom: -15px;
		}
	}
}

.left {
	&-0 {
		left: 0;
	}

	&-minus {
		&-0 {
			left: 0;
		}

		&-10 {
			left: -10px;
		}

		&-15 {
			left: -15px;
		}
	}
}

.right {
	&-0 {
		right: 0;
	}

	&-minus {
		&-0 {
			right: 0;
		}

		&-10 {
			right: -10px;
		}

		&-15 {
			right: -15px;
		}
	}
}

ion-fab-button {
	&[contentLabel] {
		margin-bottom: 20px;

		&::after {
			content: attr(contentLabel);
			font-size: 12px;
			font-weight: 500;
			color: var(--ion-color-light);
		}
	}
}

.onboarding-btn-signin {
	color: var(--ion-color-primary);
}

.onboarding-btn-register {
	color: var(--ion-color-primary);
}

@for $i from 1 through 20 {
	.letter-space-minus-0#{$i} {
		letter-spacing: #{$i * -0.1}px;
	}
}

.input-item {
	--color-activated: red;
	--color-focused: red;
}

ion-item.focus-border {
	&.item-has-focus {
		border-color: var(--ion-color-primary) !important;
	}
}

ion-input {
	&.focus-border.has-focus {
		--border-color: var(--ion-color-primary) !important;
		border-color: var(--ion-color-primary) !important;
	}
}

.image-pixelated {
	image-rendering: pixelated;
}

ion-item.arpa-input-item {
	--min-height: 56px !important;
	--max-height: 56px !important;
	&.item-has-focus {
		border-color: var(--ion-color-primary) !important;
	}

	&.item-has-value .label-floating {
		-webkit-transform: scale(1) !important;
		transform: scale(1) !important;
		margin-top: 5px;
	}
	&::part(native) {
		height: 56px;
	}

	// --border-color: ;
	--border-style: solid;
	--border-width: 1px;

	--border-radius: 8px;

	// --ripple-color: purple;

	--detail-icon-color: var(--ion-color-dark);

	&:has(.has-focus) {
		--border-color: var(--ion-color-primary);
	}

	ion-input {
		--padding-bottom: 10px !important;

		--padding-top: 2px !important;
	}
}
@for $i from 1 through 10 {
	.opacity-#{$i} {
		opacity: $i * 0.1;
	}
}

.stop-tracking,
.no-purchase {
    background-color: #f2f4f5;
    color: #8f8f8f;
    font-size: 9px;
    font-weight: 1000; // 800;
    line-height: 100%;
    letter-spacing: 0px;
    border-radius: 4px;
    text-align: center;
}

.purchase {
    background-color: #ffb83d;
    color: white;
    font-size: 9px;
    font-weight: 1000; //800;
    line-height: 100%;
    letter-spacing: 0px;
    border-radius: 4px;
    text-align: center;
}

.marker {
    background-size: contain;
    width: 32px;
    height: 33px;
    // background-position: top;
    background-repeat: no-repeat;
    background-image: url(../assets/icon/pins/white-ellipse.png);
    background-position: center bottom;
    filter: drop-shadow(3px 4px 4px rgba(0, 0, 0, 0.3));

    &.active {
        background-image: url(../assets/icon/pins/pin-white.png);
        // background-position: top;
    }

    &V4 {
        // background-image: url(../assets/images/marker_v4.png);
        // // background-image: url(./../../../../../assets/images/marker_v4.png);
        // background-size: cover;
        // width: 20px;
        // height: 50px;
        background-image: url(../assets/images/marker_v4.png);
    }

    /**
    * buradaki numaralar 918 grupIdli inspectType constantlarının ConstantId değerleridir 
    */
    // &-black,
    // &-0 {
    //     background-image: url(../assets/icon/pins/blue-ellipse.png);
    //     background-position: bottom;
    //     &.active {
    //         background-image: url(../assets/icon/pins/pin-blue.png);
    //         background-position: top;
    //     }
    // }
    &-prob-spot {
        background-image: url(../assets/icon/pins/prob-spot.svg);
        // background-position: bottom;

        &.active {
            background-image: url(../assets/icon/pins/prob-spot.svg);
            -webkit-animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            -moz-animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            -ms-animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            // background-position: top;
        }
    }

    &-newnote {
        background-image: url(../assets/icon/pins/new_note_pin.svg);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/new_note_pin_active.svg);
            background-image: url(../assets/icon/pins/new_note_pin.svg);
            -webkit-animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            -moz-animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            -ms-animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            animation: flag-pop-effect 500ms linear forwards; //, moveLeftToRight 5s linear infinite;
            // background-position: top;
        }
    }


    &-blue,
    &-1458 {
        // background-image: url(../assets/icon/pins/blue-ellipse.png);
        background-image: url(../assets/icon/pins/irrigation_1458.png);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/pin-blue.png);
            background-image: url(../assets/icon/pins/irrigation_1458.png);
            // background-position: top;
        }
    }

    &-gray,
    &-1457 {
        // background-image: url(../assets/icon/pins/gray-ellipse.png);
        background-image: url(../assets/icon/pins/nutrition_1457.png);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/pin-gray.png);
            background-image: url(../assets/icon/pins/nutrition_1457.png);
            // background-position: top;
        }
    }

    &-orange,
    &-919 {
        // background-image: url(../assets/icon/pins/orange-ellipse.png);
        background-image: url(../assets/icon/pins/disease_919.png);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/pin-orange.png);
            background-image: url(../assets/icon/pins/disease_919.png);
            // background-position: top;
        }
    }

    &-green,
    &-921 {
        // background-image: url(../assets/icon/pins/green-ellipse.png);
        background-image: url(../assets/icon/pins/wild_plant_921.png);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/pin-green.png);
            background-image: url(../assets/icon/pins/wild_plant_921.png);
            // background-position: top;
        }
    }

    &-purple,
    &-920 {
        // background-image: url(../assets/icon/pins/purple-ellipse.png);
        background-image: url(../assets/icon/pins/pest_920.png);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/pin-purple.png);
            background-image: url(../assets/icon/pins/pest_920.png);
            // background-position: top;
        }
    }

    &-yellow,
    &-1164 {
        // background-image: url(../assets/icon/pins/yellow-ellipse.png);
        background-image: url(../assets/icon/pins/other_1164.png);
        // background-position: bottom;

        &.active {
            // background-image: url(../assets/icon/pins/pin-yellow.png);
            background-image: url(../assets/icon/pins/other_1164.png);
            // background-position: top;
        }
    }
}

.custom-toggle {
    --background: var(--ion-color-light);
    --background-checked: attr("color");
    // --border-radius: 8px;
    --handle-background: var(--ion-color-ultra-light);
    --handle-background-checked: var(--ion-color-ultra-light);
    border: 1px solid var(--ion-color-light);
    border-radius: 50px;
    padding: 0;
    width: 52px;
    height: 30px;
    transition: width 110ms linear, height 110ms linear, border 110ms linear;
    --handle-spacing: 1px;
    --handle-width: 28px;
    --handle-height: 28px;

    &.toggle-checked {
        // width: 64px;
        // height: 32px;
        // padding: 3px;
        border: 1px solid var(--background);
        border-radius: 50px;
        --handle-spacing: 1px;
        --handle-width: 28px;
        --handle-height: 28px;
        transition: width 110ms linear, height 110ms linear, border 110ms linear;
    }
}

// ion-toggle {
//     --background: #ffffff;
//     --background-checked: #3880ff;
//     --border-radius: 8px;
//     --handle-background: #ffffff;
//     --handle-background-checked: #3880ff;
//     --handle-border-radius: 14px 4px 4px 14px;
//     --handle-box-shadow: 4px 0 2px 0
//     --handle-max-height: 24px
//     --handle-spacing: 2px
//     --handle-transition: background-color 15ms linear
// }

.blob {
    background: black;
    border-radius: 50%;
    border: 3px solid white;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 20px;
    width: 20px;
    // transform: scale(1);
    animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.blob.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(255, 177, 66, 0);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}


.blob.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(52, 172, 224, 0);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}

.irtem-marker {
    // box-shadow: 0 0 0 0 rgba(105, 191, 74, 1);
    // background-color: rgb(105, 191, 74);
    background-position: bottom;
    width: 34px;
    height: 34px;
    position: relative !important;
    // -webkit-animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
    // -moz-animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
    // -ms-animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
    // animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
    background-image: url(../assets/icon/pins/vra-marker.svg);
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 100%;
    bottom: -17px;

    &.pulse-effect {
        background-color: #6ABF4B99;
        z-index: 1;

        -webkit-animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
        -moz-animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
        -ms-animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
        animation: pulse-irtem 2000ms linear infinite; //, moveLeftToRight 5s linear infinite;
        // &::after {
        //     background-image: url(../assets/icon/pins/vra-marker.svg);
        //     background-size: 34px;
        //     background-repeat: none;
        //     background-position: bottom center;
        //     z-index: -1;
        //     position: absolute;
        //     content: "";
        //     width: 60px;
        //     height: 60px;
        //     background-color: rgb(105, 191, 74);
        //     border-radius: 100%;
        //     opacity: 0;
        //     top: calc(50% - 30px);
        //     left: calc(50% - 30px);
            
        // }
    }
}

@keyframes pulse-irtem {
    0% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(105, 191, 74, 0.6);
    }

    50% {
        //   transform: scale(1);
        box-shadow: 0 0 0 30px rgba(105, 191, 74, 0.4);
    }

    100% {
        //   transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(105, 191, 74, 0.2);
    }
}

.btn-height {
    &-46 {
        min-height: 46px;
        height: 46px;
    }
}

.fab-div {
    height: 28px;
    width: 28px;
    float: right;
    border-radius: 50%;
    background: var(--ion-color-medium-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-checkbox {
    // --background: var(--ion-color-ultra-light);
    // --background-checked: var(--ion-color-base);
    // --border-color: var(--ion-color-base);
    // --border-color-checked: var(--ion-color-base);
    // --border-radius: 4px;
    // --border-style: solid;
    // --border-width: 1px;
    // --checkmark-color: var(--ion-color-ultra-light);
    // --checkmark-width: 2px;
    // --size: 18px!important;
}

.custom-checkbox {
    --size: 25px;
    // --background-checked: var(--ion-color-base);
}

.custom-checkbox::part(container) {
    border-radius: 4px;
    --border-radius: 4px;
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--ion-color-medium);
    --border-color-checked: var(--ion-color-base);
    padding: 4px;
}

.mapboxgl-popup-content {
    border-radius: 5px !important;
    color: black;
}

.map-popup {
    &-container {
        max-width: unset !important;
        // width: 90%;
        border-radius: 5px;
        color: #000000;
    }

    &-btn-later {
        min-width: 56px;
        height: 31px;
        background: #FFFFFF;
        color: #000000;
        box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        padding: 5.5px 12px;
    }

    &-btn-checked {
        min-width: 56px;
        height: 31px;
        background: #000000;
        color: #FFFFFF;
        box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        padding: 5.5px 12px;
    }
}

.circled-close-icon {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    background: rgba(162, 162, 162, 0.15);
}

@keyframes flag-pop-effect {
    0% {
        //   transform: scale(0.95);
        width: 32px;
        height: 33px;
    }

    // 25% {
    //     //   transform: scale(1.25);
    // }

    // 50% {
    //     //   transform: scale(1.5);
    //     width: 48px;
    //     height: 49px;
    // }

    // 75% {
    //     //   transform: scale(1.25);
    //     width: 48px;
    //     height: 49px;
    // }

    100% {
        //   transform: scale(1);
        //     //   transform: scale(1.5);
        width: 48px;
        height: 49px;
    }
}
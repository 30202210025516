/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import url("https://fonts.googleapis.com/css2?family=Manrope");

// @font-face {
//     font-family: "Manrope";
//     // src: url(./assets/fonts/Manrope/static/Manrope-ExtraBold.ttf),
//     //     url(./assets/fonts/Manrope/static/Manrope-ExtraLight.ttf), url(./assets/fonts/Manrope/static/Manrope-Light.ttf),
//     //     url(./assets/fonts/Manrope/static/Manrope-Medium.ttf), url(./assets/fonts/Manrope/static/Manrope-Regular.ttf),
//     //     url(./assets/fonts/Manrope/static/Manrope-SemiBold.ttf);
//     src: url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf) format("ttf");
//     // src: url(./assets/fonts/Manrope/static/Manrope-Bold.ttf);
// }
// @font-face {
//     font-family: "Manrope-ExtraBold";
//     src: url(./assets/fonts/Manrope/static/Manrope-ExtraBold.ttf);
// }
// @font-face {
//     font-family: "Manrope-ExtraLight";
//     src: url(./assets/fonts/Manrope/static/Manrope-ExtraLight.ttf);
// }
// @font-face {
//     font-family: "Manrope-Light";
//     src: url(./assets/fonts/Manrope/static/Manrope-Light.ttf);
// }
// @font-face {
//     font-family: "Manrope-Medium";
//     src: url(./assets/fonts/Manrope/static/Manrope-Medium.ttf);
// }
@font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    // font-family: "Manrope-Regular";
    src: url(./assets/fonts/Manrope/static/Manrope-Regular.ttf);
}

// @font-face {
//     font-family: "Manrope-SemiBold";
//     src: url(./assets/fonts/Manrope/static/Manrope-SemiBold.ttf);
// }

@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url('https://fonts.googleapis.com/css2?family=Inter');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import "./theme/core.scss";
@import "./theme/text-core.scss";
@import "./theme/orbit-styles.scss";

@import "./../node_modules/swiper/swiper.scss";
@import "./../node_modules/swiper/swiper-vars.scss";
// @import "./../node_modules/swiper/";



// @import "~swiper/scss";
// @import "~swiper/scss/navigation";
// @import "~swiper/scss/pagination";
// @import "~swiper/css/effect-coverflow";
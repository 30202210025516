.font-family {
    &-poppins {
        font-family: "Poppins";
    }
}

.heading {
    &-lg {
        font-size: 40px;
        font-weight: 900; //700;
        line-height: 150%; //30px;
        letter-spacing: 0px;
    }

    &-md {
        font-size: 30px;
        font-weight: 900; // 700;
        line-height: 150%; //30px;
        letter-spacing: 0px;
    }

    &-sm {
        font-size: 18px;
        font-weight: 850; //700;
        line-height: 150%; //30px;
        letter-spacing: 0px;
    }

    &-xs {
        font-size: 13px;
        font-weight: 600; // 400;
        line-height: 150%; //30px;
        letter-spacing: 0px;
    }
}

.content {
    &-list-item {
        font-style: normal;
        font-weight: 700; // 500;
        font-size: 14px;
        line-height: 21px;
    }

    &-body-1 {
        font-size: 14px;
        font-weight: 600; //400;
        line-height: 20px;
        letter-spacing: 0px;
    }

    &-body-2 {}
}


.text-color {
    &-primary {
        color: var(--ion-color-primary);

        &-ion {
            --color: var(--ion-color-primary);
        }
    }

    &-dark {
        color: var(--ion-color-dark);

        &-ion {
            --color: var(--ion-color-dark);
        }
    }

    &-medium {
        color: var(--ion-color-medium);

        &-ion {
            --color: var(--ion-color-medium);
        }
    }

    &-light {
        color: var(--ion-color-light);

        &-ion {
            --color: var(--ion-color-light);
        }
    }

    &-tertiary {
        color: var(--ion-color-tertiary);

        &-ion {
            --color: var(--ion-color-tertiary);
        }
    }
}

.title {
    &-1 {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;

        /* 133.333% */
        &-medium {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            /* 133.333% */
        }

        &-bold {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 133.333% */
        }
    }

    &-2 {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;

        /* 150% */
        &-medium {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 48px;
            /* 150% */
        }

        &-bold {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            /* 150% */
        }
    }

    &-3 {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 56px;

        /* 140% */
        &-medium {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 56px;
            /* 140% */
        }

        &-bold {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 56px;
            /* 140% */
        }
    }
}

.body {
    &-large {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;

        /* 160% */
        &-medium {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            /* 160% */
        }

        &-bold {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            /* 160% */
        }
    }

    &-regular {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        /* 150% */
        &-medium {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
        }

        &-bold {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            /* 150% */
        }
    }

    &-small {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        /* 114.286% */
        &-medium {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 114.286% */
        }

        &-bold {
            color: var(--ion-text-color, #333);
            font-family: Manrope;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            /* 114.286% */
        }
    }
}

.footnote {
    color: var(--ion-text-color, #333);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    /* 133.333% */
    &-medium {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
    }

    &-bold {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 133.333% */
    }
}

.legal {
    color: var(--ion-text-color, #333);
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    /* 160% */
    &-medium {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 160% */
    }

    &-bold {
        color: var(--ion-text-color, #333);
        font-family: Manrope;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 160% */
    }
}